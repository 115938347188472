<template>
  <div class="common-bg-member">
    <div class="middle common-padding-half">
       <div class="result">
         <!--   v-if -->
         <div class="result-c">
           <img src="@/assets/img/pic14.png">
           <p>請將款項發送至下方賬戶，然後將付費憑證發送至</p>
           <b>WhatsApp 6565 1345</b>
           <p>以便公司出據收據。待管理員審核通過後，申請即刻生效！</p>
         </div>
         <div class="bank-info">
           <BankInfo
               :titleOne="bankInfo.corporation_title"
               :companyName="bankInfo.corporation"
               :titleTwo="bankInfo.fps_title"
               :fpsId="bankInfo.fps"
               :titleThree="bankInfo.bank_title"
               :accountNumber="bankInfo.bank_account"
           />
         </div>
         <div class="back-btn">
           <router-link to="/">
              進入首頁
           </router-link>
         </div>
       </div>
    </div>
  </div>
</template>

<script>
import {getBankInfo} from "@/network/main";
import BankInfo from "@/components/BankInfo";
export default {
  name: "Success",
  components:{
    BankInfo
  },
  data() {
    return {
      bankInfo:'',
    }
  },
  created() {
    this.getBankInfo()
  },
  methods:{
    getBankInfo(){
      getBankInfo().then(res =>{
        this.bankInfo =res.data.data;
      })
    },
  }
}
</script>

<style scoped>
.result-c img{
  height: 240px;
}
</style>