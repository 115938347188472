<template>
  <h3>銀行資料</h3>
  <p>{{ titleOne }}</p>
  <p><b>{{ companyName }}</b></p>
  <p>{{ titleTwo }}</p>
  <p><b>{{ fpsId }}</b></p>
  <p>{{ titleThree }}</p>
  <p><b>{{ accountNumber }}</b></p>
</template>

<script>
export default {
  name: "BankInfo",
  props: {
    titleOne: {
      type: String,
      required: true
    },
    companyName: {
      type: String,
      required: true
    },
    titleTwo: {
      type: String,
      required: true
    },
    fpsId: {
      type: String,
      required: true
    },
    titleThree: {
      type: String,
      required: true
    },
    accountNumber: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>